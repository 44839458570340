//
//
//
//
//
//
//
//
//

import UserForm from "../../../../components/UserForm.vue";
import PinEditor from "../../../../components/PinEditor.vue";
import TopicEditor from "../../../../components/TopicEditor.vue";
import EventEditor from "../../../../components/EventEditor.vue";

export default {
  name: "Service",
  components: {
    UserForm,
    PinEditor,
    TopicEditor,
    EventEditor,
  },
  data() {
    return {
      form: null,
    };
  },
  mounted() {
    switch (this.$route.params.service) {
      case "pins":
        this.form = "pin-editor";
        break;
      case "events":
        this.form = "event-editor";
        break;
      case "topics":
        this.form = "topic-editor";
        break;
      case "user":
        this.form = "user";
        break;
      default:
          this.form = null;
        break;
    }
  },
};
