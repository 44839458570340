//
//
//
//

import EventForm from './EventForm.vue'
export default {
  components: { EventForm },

}
