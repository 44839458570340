//
//
//
//

import TopicForm from './TopicForm.vue'
export default {
  components: { TopicForm },

}
