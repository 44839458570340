//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { required } from "vuelidate/lib/validators";

const { mapFields } = createHelpers({
  getterType: 'users/getField',
  mutationType: 'users/updateField',
});

export default {
  name: 'UserForm',
  components: {
  },
  data() {
    return {
      waiting: false,
      editMode: true,
      defaultImage: require("../assets/default.png"),
      file: null,
      img64: '',
      genderOptions: [
        'Feminino',
        'Masculino',
        'Outros'
      ],
      ageRangeOptions: [
        'Menos de 20 anos',
        'Entre 20 e 30 anos',
        'Entre 30 e 40 anos',
        'Entre 40 e 50 anos',
        'Entre 50 e 60 anos',
        'Mais de 60 anos'
      ],
      educationOptions: [
        'Fundamental completo',
        'Ensino médio completo',
        'Superior incompleto',
        'Superior completo',
        'Pós-graduado'
      ]
    };
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    }
  },
  computed: {
    ...mapFields({
      firstName:'userForm.firstName',
      ageRange:'userForm.ageRange',
      avatar:'userForm.avatarId',
      categoryId: 'userForm.categoryId',
      education:'userForm.education',
      email:'userForm.email',
      gender:'userForm.gender',
      lastName:'userForm.lastName',
      otherGender:'userForm.otherGender',
    }),
    ...mapGetters({
      categories: 'categories/loadCategories',
      user: 'users/getCurrentUser',
    }),
    getAvatar() {
      return `${this.$store.getters['services/getImagePath']}${this.avatar}`
    },
    getCategoryLabel() {
      return this.$store.getters["categories/getCategoryById"](this.categoryId).label
    },
    firstNameErrorMessage() {
      if (!this.$v.firstName.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    lastNameErrorMessage() {
      if (!this.$v.lastName.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
  },
  methods: {
     async encode64(){
      if (this.file !== null){
        await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.img64 = reader.result
        };
        reader.onerror = error => reject(error);
        });
      }
    },
    setEditMode() {
      this.editMode = !this.editMode;
    },
    updateUser() {
      this.waiting = true;
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.$q.notify({
          message: "Por favor, aguarde.",
          position: 'top-right',
          timeout: 1500
        });

        //se tiver imagens
        if (this.file) {
          this.$store
            .dispatch("images/uploadAvatar", { file: this.file, userId: this.user.id })
            .then((fileId) => {
              this.avatar = fileId;
              this.$store.dispatch("users/editUser");
              this.editMode = false;
            })
            .catch((error) => {
            this.waiting = false;
          });
        }
        //se não tiver imagens
        else {
          this.$store.dispatch("users/editUser");
          this.editMode = false;
        }
      } else {
        this.$q.notify({
          message: "Por favor, preencha os campos corretamente.",
          position: 'top-right',
        });
      }
    },
  },
};
